import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-selected nav-path-selected">
                    <a className="es_header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-heritage/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-heritage/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestra-historia/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/a_nossa_historia/">PT</a>
                      </li>
                      <li>
                        <a href="/it/our-heritage/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-heritage/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="heritage-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/our_heritage_1680x405_topLarge.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/our_heritage_960x405_topSmalla.jpg"
                  />
                </div>
              </div>
            </section>
            
            <div className="page-heritage__intro">
            <h1>E' NATO UNO SPECIALISTA DEL SOLE</h1>
              <p> </p>
              <p>
                Da oltre 70 anni, PIZ BUIN<sup>®</sup> dona, dovunque, agli
                amanti del sole prodotti solari che aiutano ad ottenere il
                perfetto equilibrio tra una bella abbronzatura e la protezione
                solare necessaria. Infatti, PIZ BUIN<sup>®</sup> è stato uno dei
                primi marchi a rendere più sicuro prendere il sole con
                l'implementazione del sistema del Fattore di Protezione Solare
                (SPF) già negli anni sessanta. Quindi dovunque c'e' il sole, che
                sia in spiaggia, in montagna o in citta', PIZ BUIN<sup>®</sup>{" "}
                e' li' per aiutare le persone a godersi la vita al sole, in
                sicurezza.
                <br />
                Tutto ha avuto inizio nel 1938 quando lo studente di chimica,
                Franz Greiter, prese una scottatura scalando la vetta alpina Piz
                Buin. Questo evento gli fu da ispirazione per sviluppare, nel
                1946, il primo prodotto solare al mondo, la PIZ BUIN<sup>
                  ®
                </sup>{" "}
                Glacier Cream. Collaborando con la moglie, Marga, esperta
                estetista, Franz Greiter ha creato un marchio specialista del
                sole, sviluppando prodotti solari all'avanguardia e sofisticati,
                per tutti coloro che hanno voglia di godersi la vita al sole. Da
                oltre 70 anni, PIZ BUIN<sup>®</sup> dona agli amanti del sole
                una protezione totale per permettergli di raggiungere il bel
                colorito estivo che desiderano - un colorito che ha sempre
                ispirarato le persone a domandare : “Ma dove sei stata!”
              </p>
            </div>
            <section id="content">
              <div id="loading" />
              <div className="innerContent">
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/2019-hydro-infusion-sun-gel-cream-30spf-150ml.jpg" />
                  </figure>
                  <h2 className="year_header">2019</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Hydro Infusion</strong>
                    <br />
                    Piz Buin lancio del nuovo Hydro Infusion <br />
                    Assorbimento rapido e sensazione di freschezza. <br />
                    <br />
                    <br />
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/InstantGlow_Heritage.jpg" />
                  </figure>
                  <h2 className="year_header">2017</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Instant Glow</strong>
                    <br />
                    Piz Buin lancio del nuovo Instant Glow <br />
                    Lozione solare illuminante. <br />
                    <br />
                    <br />
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/Protect_and_Cool_Frozen_640_02.jpg" />
                  </figure>
                  <h2 className="year_header">2016</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Protect &amp; Cool</strong>
                    <br />
                    Piz Buin lancio del nuovo Protect &amp; Cool.
                    <br />
                    Mousse solare rinfrescante con uma sensazione di freschezza.
                    <strong>
                      {" "}
                      <br />
                    </strong>
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/70_Years_Celebration_640_02.jpg" />
                  </figure>
                  <h2 className="year_header">2016</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Piz Buin viaggia con le sue Sun Ambassadors in Costa Rica,
                    per vivere insieme<br /> le migliori esperienze sotto il sole.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/Sun_Finder_640px_02.jpg" />
                  </figure>
                  <h2 className="year_header">2016</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>
                      Sun Finder
                      <br />
                    </strong>
                    Piz Buin compie 70 anni di protezione e bellezza sotto il
                    sole! Per celebrare<br /> questo anniversario tanto importante,
                    nasce Piz Buin sun Finder, una web app<br /> con la miglior
                    selezione delle destinazioni piu cool e glamour del mondo.
                    <strong>
                      <br />
                    </strong>
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/2015-KV-Instant-Glow-Vertical.jpg" />
                  </figure>
                  <h2 className="year_header">2015</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Introduzione del nuovo NEW PIZ BUIN INSTANT GLOW<sup>®</sup>{" "}
                    Spray illuminatore<br /> delle pelle. <br />
                    Protetta e bella - da subito e per tutta l'estate.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2014b.jpg" />
                  </figure>
                  <h2 className="year_header">2014</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> ha girato la nuova campagna WET SKIN
                    Transparent Sun Spray per<br /> l'estate 2014 in Thailandia.{" "}
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2014.jpg" />
                  </figure>
                  <h2 className="year_header">2014</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    Lancio di PIZ BUIN<sup>®</sup> ULTRA LIGHT Dry Touch Crema
                    Fluida Solare. Protezione<br /> efficace che si assorbe
                    all’istante lasciando una sensazione di leggerezza.
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2013b.jpg" />
                  </figure>
                  <h2 className="year_header">2013</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> gira la nuova campagna WET SKIN Spray
                    Solare Trasparente per<br /> l'estate 2013 alle Seychelles.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2013.jpg" />
                  </figure>
                  <h2 className="year_header">2013</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Introduzione di PIZ BUIN WET SKIN<sup>®</sup> uno dei primi
                    schermi solari che può<br /> essere applicato direttamente sulla
                    pelle bagnata.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2012.jpg" />
                  </figure>
                  <h2 className="year_header">2012</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Lancio di PIZ BUIN TAN &amp; PROTECT<sup>®</sup>. Per
                    un’abbronzatura più rapida*, più<br /> bella... sempre sicura.
                  </p>
                  <p style={{
                                  textAlign:"center"}}>
                    *Contiene una tecnologia che aumenta la naturale
                    abbronzatura
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2011.jpg" />
                  </figure>
                  <h2 className="year_header">2011</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>65° anniversario.</p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2010.jpg" />
                  </figure>
                  <h2 className="year_header">2010</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> MOUNTAIN, ora con Cold Shield Complex
                    ed estratto di stella<br /> alpina. PIZ BUIN<sup>®</sup> MOUNTAIN.
                    Protezione speciale* per condizioni di sole,<br /> freddo e vento
                    ad alta quota.
                  </p>
                  <p style={{
                                  textAlign:"center"}}>
                    *Pensata per proteggere dal sole, dal freddo e dal vento
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2009.jpg" />
                  </figure>
                  <h2 className="year_header">2009</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> ALLERGY viene arricchita con Calmanelle
                    <sup>™</sup> un complesso<br /> schermante esclusivo e testato che
                    aumenta l'elasticità della pelle*.
                  </p>
                  <p style={{
                                  textAlign:"center"}}>*Test in vitro</p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2008.jpg" />
                  </figure>
                  <h2 className="year_header">2008</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    La linea di solari PIZ BUIN<sup>®</sup> viene rinnovata con
                    la nuova tecnologia<br /> all'avanguardia Helioplex<sup>™</sup>.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2007.jpg" />
                  </figure>
                  <h2 className="year_header">2007</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Lancio di Tan Intensifier – una linea di prodotti dalla
                    formula innovativa che<br /> facilita un'abbronzatura più rapida e
                    naturale senza compromettere la<br /> protezione.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2000.jpg" />
                  </figure>
                  <h2 className="year_header">2000</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    L'intensa abbronzatura cede il passo a un "colorito baciato
                    dal sole".
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_1993.jpg" />
                  </figure>
                  <h2 className="year_header">1993</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Il primo prodotto solare PIZ BUIN<sup>®</sup> con tripla
                    protezione con vitamina E.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_1984.jpg" />
                  </figure>
                  <h2 className="year_header">1984</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    L'immagine di "Dea del sole" ci rafforza quale marchio
                    iconografico.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_1974.jpg" />
                  </figure>
                  <h2 className="year_header">1974</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Uno dei primi ad introdurre i prodotti solari resistenti
                    all'acqua.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_1962b.jpg" />
                  </figure>
                  <h2 className="year_header">1962</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>Vengono condotti dei test sulle Alpi Austro-Svizzere.</p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_1962a.jpg" />
                  </figure>
                  <h2 className="year_header">1962</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Uno dei primi ad implementare la protezione solare (SPF).
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_1946.jpg" />
                  </figure>
                  <h2 className="year_header">1946</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> lancia il primo prodotto di protezione
                    solare.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1938.jpg" />
                  </figure>
                  <h2 className="year_header">1938</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>Il Dott. Franz Greiter scala il monte Piz Buin.</p>
                  <p />
                </div>
                <span className="vline" />
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img className="year__block"width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />
        
      </div>
    );
  }
}

export default Page;
